import {
  ExpandMore,
  Favorite,
  FavoriteBorder,
  FilterAlt,
  Search,
  Sort,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Pagination,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../Shared/CustomButton";
import CustomDiv from "../../../Shared/CustomDiv";
import Text from "../../../Shared/Text";
import { IconButton } from "@mui/joy";
import axiosInstance from "../../../Config/axios";
import { API_URLS } from "../../../Config/API_URLS";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { categoryListFn } from "../../../Services/CategoryList";
import { allFiltersFn } from "../../../Services/AllFilters/index,";
import { productsFn } from "../../../Services/Products";
import { sortByFn } from "../../../Services/SortBy";
import { useDispatch } from "react-redux";
import { setThemeMode } from "../../../Redux/Actions/ThemeMode";
import classNames from "classnames";
import CustomTextField from "../../../Shared/CustomTextField";

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useQueryClient();

  const { state } = useLocation();

  const handleChange = (event, value) => {
    setPage(value);
  };

  const { mutate, isLoading } = useMutation(productsFn, {
    onSuccess: (response) => {
      setData(response.data.data.product_list);
      setCount(response.data.total_pages);
    },
  });

  const { mutate: filter, isLoading: isLoading2 } = useMutation(sortByFn, {
    onSuccess: (response) => {
      setData(response.data.data.product_list);
      setCount(0);
    },
  });

  const { mutate: allFilters, isLoading: isLoading1 } = useMutation(
    allFiltersFn,
    {
      onSuccess: (response) => {
        setData(response.data.data.product_list);
        setCount(response.data.total_pages);
      },
    }
  );

  const handleLogin = (event) => {
    event.stopPropagation();
    dispatch(setThemeMode(true));
  };
  useEffect(
    () => {
      if (state?.from === "sortby")
        filter({
          sort_by:
            state?.sortby !== "price_low_to_high" &&
            state?.sortby !== "price_high_to_low"
              ? state?.sortby
              : "None",
          price_low_to_high:
            state?.sortby === "price_low_to_high"
              ? "price_low_to_high"
              : "None",
          price_high_to_low:
            state?.sortby === "price_high_to_low"
              ? "price_high_to_low"
              : "None",
        });
    },
    // eslint-disable-next-line
    [state?.from, state?.sortby]
  );

  useEffect(
    () => {
      if (state?.from === "search")
        filter({
          search: state?.search,
        });
    },
    // eslint-disable-next-line
    [state?.search, state?.from]
  );

  useEffect(
    () => {
      if (state?.from === "allproduct") mutate({ page: page });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    // eslint-disable-next-line
    [state?.from, page]
  );
  useEffect(
    () => {
      if (state?.from === "subCategory")
        allFilters({
          page: page,
          category_id: "",
          sub_category_id: state?.subCategory,
          brand_id: "",
          color_id: "",
          max_price: "",
          min_price: "",
          rating: "",
        });
    },
    // eslint-disable-next-line
    [state?.from, state?.subCategory, page]
  );

  useEffect(
    () => {
      if (state?.from === "category")
        allFilters({
          page: page,
          category_id: state?.category,
          sub_category_id: "",
          brand_id: "",
          color_id: "",
          max_price: "",
          min_price: "",
          rating: "",
        });
    },
    // eslint-disable-next-line
    [state?.from, state?.category, page]
  );

  useEffect(
    () => {
      if (state?.from === "priceRange")
        allFilters({
          page: page,
          category_id: "",
          sub_category_id: "",
          brand_id: "",
          color_id: "",
          rating: "",
          min_price:
            state?.priceRange === "2"
              ? 0
              : state?.priceRange === "3"
              ? 100
              : state?.priceRange === "4"
              ? 200
              : state?.priceRange === "5"
              ? 500
              : "",
          max_price:
            state?.priceRange === "2"
              ? 100
              : state?.priceRange === "3"
              ? 200
              : state?.priceRange === "4"
              ? 500
              : state?.priceRange === "5"
              ? 100000
              : "",
        });
    },
    // eslint-disable-next-line
    [state?.from, state?.priceRange, page]
  );

  const handleWishList = (variant_id, event) => {
    event.stopPropagation();
    const reqBody = {
      add_quantity: 1,
      product_id: variant_id,
    };

    axiosInstance
      .post(API_URLS.addToWishList, reqBody)
      .then((response) => {
        client.refetchQueries("wishList");
        response.data.msg === "Data get Successfully"
          ? mutate({ page: page })
          : response.data.msg === "Something Wrong with the quanity !"
          ? enqueueSnackbar("Product is Out-of-Stock", { variant: "error" })
          : enqueueSnackbar(response.data.msg, { variant: "error" });
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  const addToCart = (event, variantId) => {
    console.log(variantId);
    event.stopPropagation();
    const reqBody = new FormData();
    reqBody.append("product_id", variantId);
    reqBody.append("add_quantity", 1);
    axiosInstance
      .post(API_URLS.addToCart, reqBody)
      .then((response) => {
        client.refetchQueries(["myCartList"]);
        enqueueSnackbar(response.data.status);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  const { data: mainCategoryList } = useQuery(
    ["mainCategoryList"],
    () => categoryListFn(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return (
    <CustomDiv className="bg-white  flex lg:flex-row flex-col justify-between lg:gap-5 shadow px-[3%] lg:px-[8%] lg:py-[2%] w-full">
      <CustomDiv className="flex sticky flex-col accordion min-w-[250px] lg:w-1/4">
        <CustomTextField
          startDecorator={<Search color="primary" />}
          placeholder="Search"
          value={state?.search}
          onChange={(event) =>
            navigate("/all-products", {
              state: { from: "search", search: event.target.value },
            })
          }
          className="!hidden lg:!flex"
        />

        <Accordion expanded className="lg:block hidden">
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Text className="whitespace-nowrap">
              <span className="font-bold"> Sort By :</span>
              {state?.sortby === "All Products"
                ? state?.sortby
                : state?.sortby === "sort_by_popularity"
                ? " Popularity"
                : state?.sortby === "sort_by_rating"
                ? " Rating"
                : state?.sortby === "sort_by_newness"
                ? " Newest First"
                : state?.sortby === "price_low_to_high"
                ? " Price: Low to High"
                : state?.sortby === "price_high_to_low"
                ? " Price: High to Low"
                : " None"}
            </Text>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />

            <FormControl className="!p-2">
              <RadioGroup
                onChange={(event) =>
                  navigate("/all-products", {
                    state: { from: "sortby", sortby: event.target.value },
                  })
                }
              >
                <FormControlLabel
                  value="sort_by_popularity"
                  control={<Radio color="primary" />}
                  label="Popularity"
                />
                <FormControlLabel
                  value="sort_by_rating"
                  control={<Radio color="primary" />}
                  label="Rating"
                />
                <FormControlLabel
                  value="sort_by_newness"
                  control={<Radio color="primary" />}
                  label="Newest First"
                />
                <FormControlLabel
                  value="price_low_to_high"
                  control={<Radio color="primary" />}
                  label="Price: Low to High"
                />
                <FormControlLabel
                  value="price_high_to_low"
                  control={<Radio color="primary" />}
                  label="Price: High to Low"
                />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <CustomDiv className="bg-white mt-5 dark:bg-[#121212] hidden lg:flex flex-col !rounded">
          <span className="flex justify-between p-3 px-4">
            <Text className="font-bold">Filters</Text>
          </span>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Text className="font-bold">Categories</Text>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    onChange={(event) =>
                      navigate("/all-products", {
                        state: {
                          from: "category",
                          category: event.target.value,
                        },
                      })
                    }
                  >
                    {mainCategoryList?.data?.data?.main_category_list?.map(
                      (product) => {
                        return (
                          <FormControlLabel
                            value={product.id}
                            control={<Radio color="primary" />}
                            label={product.category}
                          />
                        );
                      }
                    )}
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded>
            <AccordionSummary>
              <Text className="font-bold">Price Range</Text>
            </AccordionSummary>
            <AccordionDetails>
              <AccordionDetails>
                <FormControl>
                  <RadioGroup
                    onChange={(event) =>
                      navigate("/all-products", {
                        state: {
                          from: "priceRange",
                          priceRange: event.target.value,
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary" />}
                      label="₹0 to ₹100"
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio color="primary" />}
                      label="₹100 to ₹200"
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio color="primary" />}
                      label="₹200 to ₹500"
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio color="primary" />}
                      label="More than ₹500"
                    />
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </AccordionDetails>
          </Accordion>
        </CustomDiv>

        <CustomDiv className="flex lg:hidden bg-white p-1 my-2 w-full">
          <Button
            variant="text"
            color="primary"
            className="w-1/2 !p-2.5 !border-r-2 !border-solid !border-gray-100 !rounded-none"
            onClick={() => setOpen(true)}
            startIcon={<Sort />}
          >
            Sort
          </Button>
          <Drawer
            anchor="bottom"
            open={open}
            onClose={() => setOpen(false)}
            className="!p-10"
          >
            <CustomDiv className="py-3">
              <Text className="whitespace-nowrap px-5 py-3">
                <span className="font-bold"> Sort By :</span>
                {state?.sortby === "All Products"
                  ? state?.sortby
                  : state?.sortby === "sort_by_popularity"
                  ? " Popularity"
                  : state?.sortby === "sort_by_rating"
                  ? " Rating"
                  : state?.sortby === "sort_by_newness"
                  ? " Newest First"
                  : state?.sortby === "price_low_to_high"
                  ? " Price: Low to High"
                  : state?.sortby === "price_high_to_low"
                  ? " Price: High to Low"
                  : " None"}
              </Text>
              <Divider />
              <FormControl className="!px-5 !py-3">
                <RadioGroup
                  onChange={(event) =>
                    navigate("/all-products", {
                      state: { from: "sortby", sortby: event.target.value },
                    })
                  }
                  onClick={() => setOpen(false)}
                >
                  <FormControlLabel
                    value="sort_by_popularity"
                    control={<Radio color="primary" />}
                    label="Popularity"
                  />
                  <FormControlLabel
                    value="sort_by_rating"
                    control={<Radio color="primary" />}
                    label="Rating"
                  />
                  <FormControlLabel
                    value="sort_by_newness"
                    control={<Radio color="primary" />}
                    label="Newest First"
                  />
                  <FormControlLabel
                    value="price_low_to_high"
                    control={<Radio color="primary" />}
                    label="Price: Low to High"
                  />
                  <FormControlLabel
                    value="price_high_to_low"
                    control={<Radio color="primary" />}
                    label="Price: High to Low"
                  />
                </RadioGroup>
              </FormControl>
            </CustomDiv>
          </Drawer>

          <Button
            variant="text"
            color="primary"
            className="w-1/2 !p-2.5"
            startIcon={<FilterAlt />}
            onClick={() => setFilterDrawer(true)}
          >
            Filter
          </Button>
          <Drawer
            anchor="right"
            open={filterDrawer}
            onClose={() => setFilterDrawer(false)}
          >
            <CustomDiv className="py-3 w-64">
              <Text className="whitespace-nowrap font-bold px-5 py-3">
                Categories
              </Text>
              <Divider />
              <FormControl className="!px-5 !py-3">
                <RadioGroup
                  onChange={(event) =>
                    navigate("/all-products", {
                      state: {
                        from: "category",
                        category: event.target.value,
                      },
                    })
                  }
                  onClick={() => setFilterDrawer(false)}
                >
                  {mainCategoryList?.data?.data?.main_category_list?.map(
                    (product) => {
                      return (
                        <FormControlLabel
                          value={product.id}
                          control={<Radio color="primary" />}
                          label={product.category}
                        />
                      );
                    }
                  )}
                </RadioGroup>
              </FormControl>
              <Divider />
              <Text className="whitespace-nowrap font-bold px-5 py-3">
                Price Range
              </Text>
              <Divider />
              <FormControl className="!px-5 !py-3">
                <RadioGroup
                  onChange={(event) =>
                    navigate("/all-products", {
                      state: {
                        from: "priceRange",
                        priceRange: event.target.value,
                      },
                    })
                  }
                  onClick={() => setFilterDrawer(false)}
                >
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label="₹0 to ₹100"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio color="primary" />}
                    label="₹100 to ₹200"
                  />
                  <FormControlLabel
                    value="4"
                    control={<Radio color="primary" />}
                    label="₹200 to ₹500"
                  />
                  <FormControlLabel
                    value="5"
                    control={<Radio color="primary" />}
                    label="More than ₹500"
                  />
                </RadioGroup>
              </FormControl>
            </CustomDiv>
          </Drawer>
        </CustomDiv>
        <CustomTextField
          startDecorator={<Search color="primary" />}
          placeholder="Search"
          value={state?.search}
          onChange={(event) =>
            navigate("/all-products", {
              state: { from: "search", search: event.target.value },
            })
          }
          className="!flex !mb-2 lg:!hidden"
        />
      </CustomDiv>
      <CustomDiv className="flex flex-col lg:w-3/4">
        {isLoading1 || isLoading || isLoading2 ? (
          <Grid className="grid lg:grid-cols-3 2xl:grid-cols-4 hide-scroll grid-cols-2 md:grid-cols-3 lg:gap-4 gap-1 ">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((product) => {
              return (
                <div className="rounded-md h-auto lg:w-[250px] w-full lg:hover:scale-[1.02] transition-transform duration-200 shadow bg-white cursor-pointer  animate-pulse">
                  <div className="lg:h-52 h-40 bg-gray-300"></div>
                  <div className="flex flex-col gap-3 px-4 py-8 space-y-4 sm:p-8 bg-gray-50">
                    <div className="w-full h-6 bg-gray-300"></div>
                    <div className="w-full h-6 bg-gray-300"></div>
                    <div className="w-3/4 h-6 bg-gray-300"></div>
                  </div>
                </div>
              );
            })}
          </Grid>
        ) : (
          <Grid className="grid lg:grid-cols-3 2xl:grid-cols-4 hide-scroll grid-cols-2 md:grid-cols-3 lg:gap-4">
            {data?.map((product) => {
              return (
                <CustomDiv
                  className="rounded-md h-fit lg:w-[250px] w-full lg:hover:scale-[1.02] transition-transform duration-200 bg-white cursor-pointer "
                  onClick={() => navigate(`/product/${product?.id}`)}
                >
                  <span className="centerdiv">
                    <img
                      src={product.image}
                      alt=""
                      className="lg:h-52 relative"
                    />
                  </span>
                  <CustomDiv className="flex flex-col justify-between lg:p-4 p-2 space-y-2">
                    <CustomDiv className="space-y-2">
                      <Text className="lg:text-lg lg:block lg:w-48 w-32 overflow-hidden whitespace-nowrap text-ellipsis font-semibold">
                        {product.variant_name}
                      </Text>
                    </CustomDiv>
                    <Text className="text-sm text-green-600 uppercase">
                      {product.main_category}
                    </Text>
                    <Text
                      className={classNames(
                        "text-xs  uppercase",
                        product.stock_sataus === "In-stock"
                          ? "text-gray-600"
                          : "text-red-600"
                      )}
                    >
                      {product.stock_sataus === "In-stock"
                        ? "In-Stock"
                        : "Out-Of-Stock"}
                    </Text>
                    <CustomDiv className="flex items-center gap-2">
                      <Text className="line-through lg:text-base text-xs">
                        {product.discount_percent !== "0.0"
                          ? `₹${product.price}`
                          : null}
                      </Text>
                      <Text className="font-bold text-sm lg:text-xl">
                        ₹{product.actual_price}
                      </Text>
                      {/* <Text className="text-green-600 lg:text-base text-xs">
                        {product.discount_percent !== "0.0"
                          ? `(${product.discount_percent?.split(".0")})%`
                          : null}
                      </Text> */}
                    </CustomDiv>
                    <span className="flex lg:gap-2">
                      <IconButton
                        color="primary"
                        variant="solid"
                        onClick={(event) => {
                          localStorage.getItem("token") ? (
                            product.whishlist_status === "yes" ? (
                              <>{` ${enqueueSnackbar(
                                "Product is already in wishlist"
                              )}
                            ${event.stopPropagation()}`}</>
                            ) : (
                              handleWishList(product?.variant_id, event)
                            )
                          ) : (
                            handleLogin(event)
                          );
                        }}
                        className="!rounded !bg-[#306BDE] scale-90 lg:scale-100"
                      >
                        {product.whishlist_status === "yes" ? (
                          <Favorite />
                        ) : (
                          <FavoriteBorder />
                        )}
                      </IconButton>

                      <CustomButton
                        type="button"
                        className={
                          "!rounded whitespace-nowrap scale-90 lg:scale-100 w-full"
                        }
                        onClick={(event) =>
                          localStorage.getItem("token")
                            ? addToCart(
                              event,
                              product?.variant_id
                            )
                          : handleLogin()
                        }
                      >
                        Add To Cart
                      </CustomButton>
                    </span>
                  </CustomDiv>
                </CustomDiv>
              );
            })}
          </Grid>
        )}
        <span className="flex my-3 justify-between w-full items-center">
          {count <= 1 ? null : (
            <Pagination
              count={count}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              color="primary"
              className="w-full"
            />
          )}
        </span>
      </CustomDiv>
    </CustomDiv>
  );
};

export default AllProducts;
