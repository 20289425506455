import React from "react";
import { API_URLS } from "../../../Config/API_URLS";
import axiosInstance from "../../../Config/axios";
import { useSnackbar } from "notistack";


const Deactivate = () => {

    const { enqueueSnackbar } = useSnackbar();
    const mobile = localStorage.getItem("mobile")
    
    const deactivatefn = () => {

        axiosInstance
        .post(API_URLS.deactivate,{mobile:mobile})
        .then((response) => {
            enqueueSnackbar("Account Deactivated Successfully", { variant: "error" });
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong..!", { variant: "error" });
        });

    }

    return(
        <>

        <div className="text-2xl flex justify-center items-center text-red-500 font-bold text-center">Want to Deactivate your Account ?</div>
        <div className="flex justify-center items-center my-2"><button className="text-2xl  text-red-500 font-bold text-center border p-2 px-4" onClick={deactivatefn}>
            Click here
        </button></div>

        </>
    );
};

export default Deactivate;