import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  List,
  ListItemButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Text from "../../../../Shared/Text";
import CustomDiv from "../../../../Shared/CustomDiv";
import { CircularProgress, Divider } from "@mui/joy";
import CustomButton from "../../../../Shared/CustomButton";
import { API_URLS } from "../../../../Config/API_URLS";
import axiosInstance from "../../../../Config/axios";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Close, Visibility } from "@mui/icons-material";
import { useFormik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { resonsForCancellationFn } from "../../../../Services/ProductList";
import { returnResonsFn } from "../../../../Services/ReturnResons";
import { returnFn } from "../../../../Services/Returns";
import ReturnStatus from "./ReturnStatus";

export default function OrderDetail({ OrderId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [state, setState] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState({});
  const [bankDetailForm, setBankDetailForm] = useState(0);
  const [open1, setOpen1] = useState(false);
  const [isReturn, setIsReturn] = useState(false);
  const [returnId, setReturnId] = useState("");
  const [returnTo, setReturnTo] = useState("to_upi_id");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [upiId, setUpiId] = useState("");

  const [itemId, setItemId] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setBankDetailForm(1);
    setOpen(true);
  };
  const handleOpen = () => {
    myOrderData();
    setState(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { enqueueSnackbar } = useSnackbar();

  const { data: resonsForCancellation } = useQuery(
    ["resonsForCancellation"],
    () => resonsForCancellationFn(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const { data: returnResons } = useQuery(
    ["returnResons"],
    () => returnResonsFn(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const { mutate: returnProduct } = useMutation(returnFn, {
    onSuccess: (res) => {
      console.log(res.data);
      enqueueSnackbar(res.data.message);
      if (res.data.response_code === 200) {
        setIsReturn(false);
        setState(false);
      }
    },
  });

  const myOrderData = () => {
    setIsLoading(true);
    axiosInstance
      .post(API_URLS.myOrders, { id: OrderId })
      .then((response) => {
        setData(response.data.data);
        setItems(response.data.order_items_data);
        setStatus(response?.data?.order_items_data?.if_live);
        setIsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };

  const client = useQueryClient();
  const initialValues = {
    account_holder_name: "",
    bank_account_details: "",
    bank_ifsc_details: "",
    reason_for_cancellation_id: "",
  };
  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: initialValues,

    onSubmit: (values, action) => {
      const reqbody = new FormData();
      reqbody.append("order_id", data?.orderid_id);
      reqbody.append("account_holder_name", values.account_holder_name);
      reqbody.append("bank_account_details", values.bank_account_details);
      reqbody.append("bank_ifsc_details", values.bank_ifsc_details);
      reqbody.append(
        "reason_for_cancellation_id",
        values.reason_for_cancellation_id
      );
      axiosInstance
        .post(API_URLS.orderCancellation, reqbody)
        .then((response) => {
          enqueueSnackbar(response.data.message);
          client.refetchQueries("myOrderList");
          setState(false);
          setOpen(false);
        })
        .catch((error) => {
          enqueueSnackbar("Something went wrong..!", { variant: "error" });
        });
    },
  });
  const handleReturn = (event) => {
    event.preventDefault();
    returnProduct({
      order_item_id: itemId,
      reason_for_return_id: returnId,
      payment_refund_by: returnTo,
      bank_account_details: accountNumber,
      bank_ifsc_details: ifsc,
      upi_id: upiId,
    });
  };
  
  return (
    <>
      <Visibility
        onClick={handleOpen}
        className="cursor-pointer bg-[#306BDE] rounded text-white !p-2 !text-4xl"
      />
      <Drawer
        anchor={"right"}
        open={state}
        onClose={() => setState(false)}
        PaperProps={{ className: "!bg-white !bg-opacity-90 !backdrop-blur" }}
      >
        {isLoading ? (
          <>
            <CustomDiv className="flex h-full lg:w-[600px] w-screen justify-center items-center">
              <CircularProgress size="lg" color="primary" />
            </CustomDiv>
          </>
        ) : (
          <CustomDiv className="flex gap-4 flex-col lg:w-[600px] w-screen p-6">
            <Text className="text-lg font-semibold">Order Detail</Text>
            <Text>Delivery Address</Text>
            <span className="flex flex-col shadow border-2 border-[#306BDE] cursor-pointer p-5 rounded">
              <Text className="font-semibold">
                {data?.user_address?.address_type}
              </Text>
              <Text className="font-semibold">
                {data?.customer_first_name} {data?.customer_last_name}
              </Text>
              <Text>
                {data?.customer_address_2}, {data?.customer_pincode},{" "}
                {data?.customer_city}, {data?.customer_state},{" "}
                {data?.customer_country},
              </Text>
            </span>

            <div className="flex flex-col border-2 border-blue-200 rounded-md">
              <p className="p-2">Delivery</p>
              <Divider />
              <div className="p-2">
                <p className="capitalize font-semibold">
                  Status : {status?.status || data?.order_status}
                </p>
                <p>
                  Date :{" "}
                  {status?.order_timings?.order_ended_time ||
                    data?.order_date?.slice(0, 10)}
                </p>
              </div>
            </div>

            <table className="w-full">
              <thead className="!text-center lg:text-sm  text-xs !bg-gray-300 !rounded">
                <tr>
                  <th className="!p-2 !text-start">Items Name</th>
                  <th className="!p-2 !text-center">Quantity</th>
                  <th className="!p-2 !text-center">Price</th>
                  <th className="!p-2 !text-center">Return/Replacement</th>
                </tr>
              </thead>
              <tbody className="!overflow-y-auto">
                {items?.order_items?.map((order) => {
                  return (
                    <tr className="border-b">
                      <td className="!p-2 lg:text-sm text-xs lg:font-bold font-semibold flex lg:flex-row flex-col gap-2 items-start">
                        <img
                          src={order.product_images}
                          alt=""
                          className="w-16 h-16 rounded"
                        />
                        {order.product_name}
                      </td>

                      <td className="!p-2">{order.quantity}x</td>
                      <td className="!p-2">₹{order.price}</td>
                      <td className="">
                        {data?.order_status === "Delivered" &&
                          !order.is_active_return &&
                          !order.is_active_replacement && <span>N/A</span>}
                        <>
                          {data?.order_status === "Delivered" &&
                            order.is_active_return &&
                            items?.order_return_list?.length === 0 && (
                              <span className="flex flex-col items-center gap-1">
                                <Text
                                  className="bg-blue-500 lg:cursor-pointer rounded-sm p-2 text-white"
                                  onClick={() => {
                                    setOpen1(true);
                                    setItemId(order.id);
                                  }}
                                >
                                  Return
                                </Text>
                              </span>
                            )}
                          {data?.order_status === "Delivered" &&
                            order.is_active_return &&
                            items?.order_return_list?.length !== 0 && (
                              <span className="flex flex-col items-center gap-1 text-xs">
                                <p>Return Request Sent</p>
                                <ReturnStatus
                                  items={order}
                                  status={
                                    items?.order_return_list?.filter(
                                      (re) => re.sales_item === order.id
                                    )?.[0]
                                  }
                                />
                              </span>
                            )}
                          {data?.order_status === "Delivered" &&
                            order.is_active_replacement &&
                            items?.order_replacement_list?.length === 0 && (
                              <span className="flex flex-col items-center gap-1">
                                <Text
                                  onClick={() => {}}
                                  className="bg-blue-500 lg:cursor-pointer rounded-sm p-1 text-white"
                                >
                                  Replacement
                                </Text>
                              </span>
                            )}
                        </>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <CustomDiv className="flex flex-col items-end gap-3 w-full">
              <span className="flex justify-between gap-4 w-full">
                <Text>Total Amount:</Text>
                <Text>₹{data?.total_price}</Text>
              </span>
              <span className="flex justify-between gap-4 w-full">
                <Text>Total Tax Amount:</Text>
                <Text>+₹{data?.total_tax_amount}</Text>
              </span>
              <span className="flex justify-between gap-4 w-full">
                <Text>Discount:</Text>
                <Text>-₹{data?.total_discount}</Text>
              </span>

              <span className="flex justify-between gap-4 w-full">
                <Text>Delivery Charge:</Text>
                <Text>
                  ₹
                  {data?.total_delivery_charge
                    ? data?.total_delivery_charge
                    : 0}
                </Text>
              </span>
              <Divider />
              <span className="flex font-semibold w-full justify-between gap-4">
                <Text>Total Order Amount:</Text>
                <Text>₹{data?.total_amount}</Text>
              </span>
            </CustomDiv>
            <CustomDiv className="flex gap-4">
              <CustomButton
                className="!w-full"
                onClick={() => setState(false)}
                variant="outlined"
              >
                Back to Orders
              </CustomButton>
              {data?.order_status === "Pending" && (
                <CustomButton className="!w-full" onClick={handleClickOpen}>
                  Cancel Order
                </CustomButton>
              )}
            </CustomDiv>
          </CustomDiv>
        )}
        {bankDetailForm === 0 ? (
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Order Cancellation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel this order.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={handleCloseDialog} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent className="">
              <form onSubmit={handleSubmit}>
                <Text className="text-xl text-center font-semibold my-5">
                  Enter Your Bank Detail
                </Text>
                <CustomDiv className="grid grid-cols-1 lg:w-96 gap-5">
                  <span className="w-full">
                    <Text>Account Holder Name</Text>
                    <TextField
                      type="text"
                      size="small"
                      color="primary"
                      id="account_holder_name"
                      name="account_holder_name"
                      value={values["account_holder_name"]}
                      onChange={handleChange}
                      className="!w-full !rounded"
                    />
                  </span>
                  <span className="w-full">
                    <Text>Account Number</Text>
                    <TextField
                      type="number"
                      size="small"
                      color="primary"
                      id="bank_account_details"
                      name="bank_account_details"
                      value={values["bank_account_details"]}
                      onChange={handleChange}
                      className="!w-full !rounded"
                    />
                  </span>
                  <span className="w-full">
                    <Text>IFSC</Text>
                    <TextField
                      type="text"
                      size="small"
                      color="primary"
                      id="bank_ifsc_details"
                      name="bank_ifsc_details"
                      value={values["bank_ifsc_details"]}
                      onChange={handleChange}
                      className="!w-full !rounded"
                    />
                  </span>
                  <span className="w-full">
                    <Text>Resons For Cancellation*</Text>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        color="primary"
                        id="reason_for_cancellation_id"
                        name="reason_for_cancellation_id"
                        value={values["reason_for_cancellation_id"]}
                        onChange={handleChange}
                      >
                        {resonsForCancellation?.data?.data?.map((resons) => {
                          return (
                            <MenuItem value={resons.id}>
                              {resons.choice}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </span>
                </CustomDiv>
                <span className="flex items-center gap-4">
                  <CustomButton
                    type="button"
                    onClick={handleClose}
                    className="!w-fit lg:!w-full !my-5"
                    variant="outlined"
                  >
                    Back
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    className="!w-full whitespace-nowrap !my-5"
                  >
                    Confirm Cancellation
                  </CustomButton>
                </span>
              </form>
            </DialogContent>
          </Dialog>
        )}
        <Dialog
          open={open1}
          onClose={() => setOpen1(false)}
          PaperProps={{ className: "!m-0 w-1/2" }}
        >
          <DialogContent className="!p-0">
            <p className="p-3 lg:text-xl font-semibold">Return/Replacement</p>
            <Divider />
            <List>
              <ListItemButton
                onClick={() => {
                  setIsReturn(true);
                  setOpen1(false);
                }}
              >
                Return
              </ListItemButton>
              <ListItemButton>Replacement</ListItemButton>
            </List>
          </DialogContent>
        </Dialog>

        <Dialog
          open={isReturn}
          onClose={() => setIsReturn(false)}
          PaperProps={{ className: "!m-0 w-1/2" }}
        >
          <DialogContent className="!p-0">
            <span className="flex justify-between">
              <p className="p-3 text-xl font-semibold">Return</p>
              <Close />
            </span>

            <Divider />
            <form onSubmit={handleReturn} className="p-3 flex flex-col gap-2">
              <span>
                <Text>Refund To*</Text>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    color="primary"
                    value={returnTo}
                    onChange={(event) => setReturnTo(event.target.value)}
                  >
                    <MenuItem value={"to_account_number"}>
                      Bank Account
                    </MenuItem>
                    <MenuItem value={"to_upi_id"}>UPI</MenuItem>
                  </Select>
                </FormControl>
              </span>

              <span>
                <Text>Resons For Return*</Text>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    color="primary"
                    value={returnId}
                    onChange={(event) => setReturnId(event.target.value)}
                  >
                    {returnResons?.data?.data?.map((resons) => {
                      return (
                        <MenuItem value={resons.id}>{resons.choice}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </span>

              {returnTo === "to_account_number" ? (
                <>
                  <span>
                    <Text>Account No*</Text>
                    <TextField
                      type="text"
                      size="small"
                      color="primary"
                      value={accountNumber}
                      onChange={(event) => setAccountNumber(event.target.value)}
                      className="!w-full !rounded"
                    />
                  </span>
                  <span>
                    <Text>IFSC*</Text>
                    <TextField
                      type="text"
                      size="small"
                      color="primary"
                      value={ifsc}
                      onChange={(event) => setIfsc(event.target.value)}
                      className="!w-full !rounded"
                    />
                  </span>
                </>
              ) : (
                <>
                  <span>
                    <Text>UPI*</Text>
                    <TextField
                      type="text"
                      size="small"
                      color="primary"
                      value={upiId}
                      onChange={() => setUpiId}
                      className="!w-full !rounded"
                    />
                  </span>
                </>
              )}

              <span className="flex justify-center p-2">
                <CustomButton type="submit">Submit</CustomButton>
              </span>
            </form>
          </DialogContent>
        </Dialog>
      </Drawer>
    </>
  );
}
