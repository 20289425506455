import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { categoryListFn } from "../../Services/CategoryList";
import { useMutation } from "react-query";
import classNames from "classnames";

const CategoryPage = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const { mutate: category } = useMutation(categoryListFn, {
    onSuccess: (response) => {
      setCategories(response.data?.data?.main_category_list);
      console.log("hghffhjjfh====>", response.data?.data?.main_category_list);
    },
  });

  useEffect(() => {
    category();
  }, []);

  return (
    <div className="lg:px-60">
      {categories.map((cat, index) => {
        return (
          <>
            {" "}
            <div
              className={classNames(
                "lg:h-48 h-48 bg-gradient-to-r from-gray-300 flex justify-around",
                index % 2 === 0 && "!hidden"
              )}
            >
              <div className="flex flex-col justify-center items-center">
                <img
                  alt=""
                  src={cat.image}
                  className="w-28 h-28 rounded-full flex flex-col justify-center items-center"
                />
              </div>
              <div className="flex flex-col justify-center items-center">
                <p className="text-2xl font-bold">{cat.Meta_Title}</p>
                {cat.sub_category_list.map((item) => {
                  return (
                    <p
                      className="font-semibold"
                      onClick={() =>
                        navigate(`/all-products`, {
                          state: {
                            from: "subCategory",
                            subCategory: item.id,
                          },
                        })
                      }
                    >
                      {item.subcategory},
                    </p>
                  );
                })}
              </div>
            </div>
            <div
              className={classNames(
                "lg:h-48 h-48 bg-gradient-to-r from-gray-300 flex  justify-around",
                index % 2 !== 0 && "!hidden"
              )}
            >
             
              <div className="flex flex-col justify-center items-center">
                <p className="text-2xl font-bold">{cat.Meta_Title}</p>
                {cat.sub_category_list.map((item) => {
                  return (
                    <p
                      className="font-semibold"
                      onClick={() =>
                        navigate(`/all-products`, {
                          state: {
                            from: "subCategory",
                            subCategory: item.id,
                          },
                        })
                      }
                    >
                      {item.subcategory},
                    </p>
                  );
                })}
              </div>
              <div className="flex flex-col justify-center items-center">
                <img
                  alt=""
                  src={cat.image}
                  className="w-28 h-28 rounded-full flex flex-col justify-center items-center"
                />
              </div>
            </div>
          </>
        );
      })}
      {/* <div className='lg:h-44 h-36 bg-gradient-to-r from-gray-300 flex justify-around' onClick={()=>navigate("/all-products")}>
    <img alt='' src={men2}/><div className='flex flex-col justify-center items-center'><p className='text-2xl font-bold'>Furnishing</p><p className='font-semibold'>Rugs, Throws, Doormates, Cushions..</p></div>
</div> */}
      {/* <div onClick={()=>navigate("/all-products")} className=' lg:h-44 h-36 bg-gradient-to-l from-gray-300 flex justify-around' >
<div className='flex flex-col justify-center items-center'><p className='text-2xl font-bold'>Bath</p><p className='font-semibold'>Bath Mates</p></div>    <img alt='' src={men1}/>
</div>
<div onClick={()=>navigate("/all-products")} className=' lg:h-44 h-36 bg-gradient-to-r from-gray-300 flex justify-around'>
    <img alt='' src={men2}/><div className='flex flex-col justify-center items-center'><p className='text-2xl font-bold'>Kids</p><p className='font-semibold'>Kids Sale, Kids Bathmates, Kids Towel, Kids Bedding, Baby Bedding</p></div>
</div>
<div onClick={()=>navigate("/all-products")} className=' lg:h-44 h-36 bg-gradient-to-l from-gray-300 flex justify-around'>
<div className='flex flex-col justify-center items-center'><p className='text-2xl font-bold'>Accessories</p><p className='font-semibold'>Printed accents, Leather accents, Home accents</p></div> <img alt='' src={men1}/>
</div>
<div onClick={()=>navigate("/all-products")} className=' lg:h-44 h-36 bg-gradient-to-r from-gray-300 flex justify-around'>
    <img alt='' src={men2}/><div className='flex flex-col justify-center items-center'><p className='text-2xl font-bold'>Bedding</p><p className='font-semibold'>Night Linen, Day Bedding</p></div>
</div> */}
    </div>
  );
};

export default CategoryPage;
