import React, { useState } from "react";
import CustomDiv from "../CustomDiv";
import logo from "../../Assets/zzz.jpeg";
import { useNavigate } from "react-router-dom";
import Text from "../Text";
import { useMutation, useQuery } from "react-query";
import { myWishList } from "../../Services/WishList";
import AddToCart from "./AddToCart";

import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  ExpandLess,
  ExpandMore,
  KeyboardArrowDownOutlined,
  MenuTwoTone,
} from "@mui/icons-material";
import { categoryListFn } from "../../Services/CategoryList";
import { useDispatch } from "react-redux";
import { setThemeMode } from "../../Redux/Actions/ThemeMode";
import { productsFn } from "../../Services/Products";

const Headers = () => {
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState([]);
  const [spices, setSpices] = React.useState([]);
  const { mutate: allProducts } = useMutation(productsFn, {
    onSuccess: (response) => {
      setSpices(response.data.data.product_list);
    },
  });

  React.useEffect(
    () => {
      allProducts();
    },
    // eslint-disable-next-line
    [""]
  );

  const products = spices?.map((product) => {
    return {
      image: product.image,
      variant: product.variant_name,
      sub_category: product.sub_category,
      id: product.id,
    };
  });

  const handleClick = (id) => {
    setCollapse((collapse) => [...collapse, id]);
  };
  const handleClose = (id) => {
    setCollapse(collapse.filter((item) => item !== id));
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { data } = useQuery(["wishList"], () => myWishList(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const { data: mainCategoryList } = useQuery(
    ["mainCategoryList"],
    () => categoryListFn(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const handleChange = (event) => {
    navigate(`/product/${event.currentTarget.lastChild.innerHTML}`);
    sessionStorage.clear();
    setOpen(false);
  };

  return (
    <CustomDiv className="lg:h-16 h-[25px] sticky z-50 shadow">
      <CustomDiv className="flex flex-col justify-between items-center py-3 fixed z-50 shadow bg-white bg-opacity-75 text-black backdrop-blur w-full px-[4%] lg:px-[8%]">
        <CustomDiv className="flex items-center justify-between w-full">
          <span className="flex items-center gap-5">
            <img
              src={logo}
              alt=""
              className="relative h-10   cursor-pointer"
              onClick={() => navigate("/")}
            />
          </span>

          <Autocomplete
            options={products}
            autoHighlight
            size="small"
            className="Product -mr-5"
            freeSolo
            sx={{ width: 200 }}
            onChange={handleChange}
            disableClearable
            getOptionLabel={(option) => option.variant}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="50"
                  src={option.image}
                  srcSet={option.image}
                  alt=""
                />
                <span className="flex flex-col">
                  <span className="text-sm">{option.variant}</span>
                  <span className="text-sm">{option.sub_category}</span>
                </span>

                <span id="variant_id" hidden>
                  {option.id}
                </span>
              </Box>
            )}

            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search Products"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", 
                }}
              />
            )}
          />
       
          <span className="flex items-center gap-4 lg:gap-7">
        
            <IconButton >
            <AddToCart  />
             <MenuTwoTone onClick={()=>setOpen(true)} className="!block lg:!hidden !text-black " />
            </IconButton>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
              <CustomDiv>
                <List className="!pr-0">
                  <span className="!p-4 flex justify-center">
                    <Autocomplete
                      options={products}
                      autoHighlight
                      size="small"
                      className="Product "
                      freeSolo
                      sx={{ width: 230 }}
                      onChange={handleChange}
                      disableClearable
                      getOptionLabel={(option) => option.variant}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="50"
                            src={option.image}
                            srcSet={option.image}
                            alt=""
                          />
                          <span className="flex flex-col">
                            <span className="text-sm">{option.variant}</span>
                            <span className="text-xs">
                              {option.sub_category}
                            </span>
                          </span>

                          <span id="variant_id" hidden>
                            {option.id}
                          </span>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search Products"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", 
                          }}
                        />
                      )}
                    />
                  </span>

                  <ListItemButton
                    onClick={() =>
                      navigate("/all-products", {
                        state: { from: "allproduct" },
                      })
                    }
                    className="!flex !justify-between !w-full"
                  >
                    All Products
                  </ListItemButton>
                  {mainCategoryList?.data?.data?.main_category_list?.map(
                    (product) => {
                      return (
                        <>
                          <ListItemButton
                            onClick={() => {
                              return (
                                <>
                                  {collapse.indexOf(product.id) === -1
                                    ? handleClick(product.id)
                                    : handleClose(product.id)}
                                </>
                              );
                            }}
                            className="!flex !justify-between !w-full"
                          >
                            <span>{product.category}</span>

                            {collapse ? <ExpandMore /> : <ExpandLess />}
                          </ListItemButton>
                          <Collapse
                            in={collapse.indexOf(product.id) !== -1}
                            className=""
                          >
                            <List>
                              {product?.sub_category_list?.map(
                                (subCategory) => {
                                  return (
                                    <ListItemButton
                                      className="!flex !pl-8 !justify-between !w-full"
                                      onClick={() =>
                                        navigate(`/all-products`, {
                                          state: {
                                            from: "subCategory",
                                            subCategory: subCategory.id,
                                          },
                                        })
                                      }
                                    >
                                      {subCategory.subcategory}
                                    </ListItemButton>
                                  );
                                }
                              )}
                            </List>
                          </Collapse>
                        </>
                      );
                    }
                  )}
                </List>
              </CustomDiv>
              <div className="flex fixed w-full z-50 bottom-0 border-t-2 p-2 bg-white">
                <Button
                  variant="contained"
                  className="!px-10"
                  onClick={() => setOpen(false)}
                >
                  <ArrowBack/>
                </Button>
              </div>
            </Drawer>
          </span>
        </CustomDiv>
      </CustomDiv>
      <CustomDiv className="hidden lg:flex text-black justify-center gap-10 items-end lg:pt-[110px] h-[70px]">
        <Typography
          onClick={() =>
            navigate("/all-products", {
              state: { from: "allproduct" },
            })
          }
          className="whitespace-nowrap cursor-pointer hover:bg-gray-200 font-semibold py-2 px-4 rounded inline-flex items-center"
        >
          <Text>All Products</Text>
        </Typography>
        {mainCategoryList?.data?.data?.main_category_list
          ?.slice(0, 6)
          ?.map((product) => {
            return (
              <div className="group inline-block relative">
                <Typography className="whitespace-nowrap cursor-pointer hover:bg-gray-200 font-semibold py-2 px-4 rounded inline-flex items-center">
                  <Text>{product.category}</Text> <KeyboardArrowDownOutlined />
                </Typography>
                <ul className="absolute hidden w-96 p-2 z-50 shadow bg-white bg-opacity-80 backdrop-blur text-black border-t-4 border-[#306BDE] rounded-sm group-hover:grid group-hover:grid-cols-2">
                  {product?.sub_category_list?.map((subCategory) => {
                    return (
                      <li
                        key={subCategory.id}
                        className="p-2 flex flex-col gap-2 cursor-pointer hover:underline"
                        onClick={() =>
                          navigate(`/all-products`, {
                            state: {
                              from: "subCategory",
                              subCategory: subCategory.id,
                            },
                          })
                        }
                      >
                        {subCategory.subcategory}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
      </CustomDiv>
    </CustomDiv>
  );
};

export default Headers;
